import React from 'react'

export default function Bedding() {
  return (
    <div className="" id="experience">
      <div class="mx-5">
        <div className="mb-3 pt-2 text-right header">
          <h1><strong>Textile Design Experience</strong></h1>
          <p>2017 - present</p>
        </div>
        <div className="description">
          My professional experience working as a textile designer included working within a creative team to produce home goods for various entertainment licenses, including: Minecraft, Fortnite, Warner Brosthers, Netflix, Marvel. 
          These products were sold by various retail stores across world at stores such as Target, Walmart, Costco, Burlington and Hot Topic.
          My responsibilities included leveraging my skills in Adobe Illustrator, Adobe Photoshop and my knowledge in illustration and design to create products that upheld the licensors desires while also fulfilling the buyers requests.
          <br />
          <br />
          Below are links to a few products that I designed at Jay Franco & Sons currently being sold.
        </div>

        <div className="bedding-work">
          <div className="project-img-container">
            <a href="https://www.amazon.com/Dungeons-Dragons-Adventure-Squad-Blanket/dp/B08JP7JZHW/ref=sr_1_24?dchild=1&keywords=dungeons+and+dragons+bedding&qid=1624625622&sr=8-24" target="blank">
              <img src="/bedding/dnd-blanket.jpg" className="rounded project-img" alt="D&D Adventure Squad blanket being sold on Amazon" />
            </a>
          </div>
          <div className="project-img-container">
            <a href="https://www.amazon.com/Jay-Franco-Fortnite-Warhol-Piece/dp/B07ZTV1Q1T/ref=sr_1_6?dchild=1&keywords=fortnite+bedding&qid=1624625763&sr=8-6" target="blank">
              <img src="/bedding/neon-bed.jpg" className="rounded project-img" alt="Fortnite Warhol bedding beind sold on Amazon" />
            </a>
          </div>
          <div className="project-img-container">
            <a href="https://www.amazon.com/Jay-Franco-Minecraft-Animal-Patch/dp/B08V7HH998/ref=sr_1_9?dchild=1&keywords=minecraft%2Bbedding&qid=1624625525&sr=8-9&th=1" target="blank">
              <img src="/bedding/animals-bed.jpg" className="rounded project-img" alt="Minecraft Animal Patches bedding being sold on Amazon" />
            </a>
          </div>
          <div className="project-img-container">
            <a href="https://www.walmart.com/ip/Minecraft-Green-Blue-Collage-Kids-Bed-in-a-Bag-Bedding-Set-w-Reversible-Comforter/856469531?findingMethod=wpa" target="blank">
              <img src="/bedding/collage-bed.jpg" className="rounded project-img" alt="Minecraft Green Blue Collage bedding being sold at Walmart" />
            </a>
          </div>
          <div className="project-img-container">
            <a href="https://www.target.com/p/minecraft-patchwork-mobs-bed-in-a-bag/-/A-81498904?preselect=80286372#lnk=sametab" target="blank">
              <img src="/bedding/patchwork-bed.jpg" className="rounded project-img" alt="Minecraft Patchwork of Mobs bedding being sold at Target" />
            </a>
          </div>
          <div className="project-img-container">
            <a href="https://www.target.com/p/swimming-around-beach-towel-blue-minecraft/-/A-54649002#lnk=sametab" target="blank">
              <img src="/bedding/swim-beach.jpg" className="rounded project-img" alt="Minecraft Swimming Around beach towel being sold at Target" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}