import React from 'react'

export default function Navbar() {
  return (
    <nav className="fixed-nav-bar d-flex justify-content-between align-items-center">
      <div className="text-white pl-4 pt-1">
        <h1 className="display-4 logo"><b>R.</b></h1>
      </div>
      <div className="nav-links text-white pr-4">
        <a href="/#about">About</a>{" | "}
        <a href="/#projects">Projects</a>{" | "}
        <a href="/#experience">Experience</a>{" | "}
        <a href="/#contact">Contact</a>
      </div>
    </nav>
  )
}
