import React from 'react'

export default function Projects() {
  return (
    <div className="" id="projects">
      <div className="mx-5">
        <div className="mb-3 text-white pt-2">
          <h1><strong>Projects</strong></h1>
        </div>

        <div className="styled-con d-flex mb-4 pt-3">
          <div className="project-img-container">
            <img src="/projects/spellbook.jpg" className="rounded project-img" alt="RPG Spellbook App screenshot" />
          </div>
          <div className="pl-4 d-flex flex-column">
            <h3 className="pt-2"><strong>Role-Playing Game Spell Book</strong></h3>
            <div className="pt-2">
              RPG Spellbook is an application for users to manage their Dungeons & Dragons (D&D) 5th edition character arcane
              spells. Users have access to a responsive react based table and clean UI to provide a more user friendly way of
              managing their spellcasters.
            </div>
            <div className="pt-2">
              In this project I imported and used the react-table library to build a responsive table in order to display hundreds of D&D 5th edition spells.
              I also utilized JSON web tokens and localStorage to add client-side authorization and store encrypted user information.
              Built a Rails based API with which handled management of my models saved to a PostgreSQL database.
              Information from the API was handled on the front-end using Redux and ensured up to date data was accessible to the user without needing a page refresh. 
            </div>
            <div className="pt-2">
              <strong>Technologies: React.js, Redux, Ruby on Rails API, PostgreSQL, Bootstrap, HTML, CSS</strong>
            </div>
            <div className="pt-2 project-links">
              <a href="https://www.youtube.com/watch?v=MvgMYP8EYvI&ab_channel=rncongi" target="blank"><strong>Demo Video</strong></a> | <a href="https://github.com/BeccaN/spell-book-frontend" target="blank"><strong>GitHub Link</strong></a>
            </div>
          </div>
        </div>

        <div className="styled-con d-flex mb-4 pt-3">
          <div className="project-img-container">
            <img src="/projects/bootoons.jpg" className="rounded project-img" alt="Bootoons site screenshot" />
          </div>
          <div className="pl-4 d-flex flex-column">
            <h3 className="pt-2"><strong>Bootoons</strong></h3>
            <div className="pt-2">
              Bootoons is a single page application for users to share and peruse through various categories of comics.
              The application utilizes a highly accessible UI/UX design and is built entirely with vanilla JavaScript.
            </div>
            <div className="pt-2">
              For this project I assembled a fully functioning single page application using vanilla JavaScript.
              I constructed a Comic JavaScript class and added comic related functionality to this class following the SOLID design principles.
              I also explored styling frameworks and built a uniquely stylized UI with TailwindCSS equipped with custom styling configurations.
              With a JavaScript frontend, I also built a Ruby on Rails API backend that stored comic related data on a local PostgreSQL database, and used fetch requests from the front-end to GET and POST data.
            </div>
            <div className="pt-2">
              <strong>Technologies: JavaScipt(ES6+), Ruby on Rails API, PostgreSQL, TailwindCSS, HTML, CSS, SOLID, REST</strong>
            </div>
            <div className="pt-2 project-links">
              <a href="https://www.youtube.com/watch?v=crQeSL-C_N0&t=1s&ab_channel=rncongi" target="blank"><strong>Demo Video</strong></a> | <a href="https://github.com/BeccaN/bootoons" target="blank"><strong>GitHub Link</strong></a>
            </div>
          </div>
        </div>

        <div className="styled-con d-flex mb-4 pt-3">
          <div className="project-img-container">
            <img src="/projects/trunk-cards.jpg" className="rounded project-img" alt="Trunk Cards application screenshot" />
          </div>
          <div className="pl-4 d-flex flex-column">
            <h3 className="pt-2"><strong>Trunk Cards</strong></h3>
            <div className="pt-2">
              Trunk Cards is a simple terms and definitions study guide. Users can create an account or login using GitHub, and then create a set of terms with definitions. They can study those terms or browse through categories of term sets other users have created. 
            </div>
            <div className="pt-2">
              This application was built using Ruby on Rails with a SQLite 3 database.
              Following the MVC (Model View Controller) design pattern, I created a series of views that were rendered based on the controller method being accessed according the current route.
              I made use of ActiveRecord validations to ensure proper data was being saved to the database. I also utilized scope model methods to order and format data.
              This application has full CRUD capabilities, and follows REST url naming conventions.
            </div>
            <div className="pt-2">
              <strong>Technologies: Ruby on Rails, SQLite 3, HTML, CSS, MVC, REST, CRUD</strong>
            </div>
            <div className="pt-2 project-links">
              <a href="https://www.youtube.com/watch?v=q39Yl6Vjpvo&t=2s&ab_channel=rncongi" target="blank"><strong>Demo Video</strong></a> | <a href="https://github.com/BeccaN/trunk-cards" target="blank"><strong>GitHub Link</strong></a>
            </div>
          </div>
        </div>

        <div className="styled-con d-flex mb-4 pt-3">
          <div className="project-img-container">
            <img src="/projects/dnd-manager.jpg" className="rounded project-img" alt="D&D Character Manager application screenshot" />
          </div>
          <div className="pl-4 d-flex flex-column">
            <h3 className="pt-2"><strong>Dungeons and Dragons(D&D) Character Manager</strong></h3>
            <div className="pt-2">
              D&D Character Manager is a Sinatra based CRUD/MVC model application that allows users to track and manage their D&D characters. Users can also view other users characters to help get inspired for their next D&D character!
            </div>
            <div className="pt-2">
              This app was built using Sinatra with a SQLite 3 database. Users have full CRUD capabilities with their D&D characters via ActiveRecord.
              I imported and utilized the 'bcrypt' gem to secure the users passwords on the database.
              I also built a character creation form using HTML and included ActiveRecord validations on the character model to ensure proper data was being saved to the database.
              This simple application accurately portrays my foundational knowledge on the MVC model and REST.
            </div>
            <div className="pt-2">
              <strong>Technologies: Sinatra, Ruby, SQLite 3, HTML, CSS, MVC, REST, ActiveRecord</strong>
            </div>
            <div className="pt-2 project-links">
              <a href="https://www.youtube.com/watch?v=Z5tr9ocDMLM&t=70s" target="blank"><strong>Demo Video</strong></a> | <a href="https://github.com/BeccaN/DnD-Character-Manager" target="blank"><strong>GitHub Link</strong></a>
            </div>
          </div>
        </div>

        <div className="styled-con d-flex mb-4 pt-3">
          <div className="project-img-container">
            <img src="/projects/nyt-bestsellers.jpg" className="rounded project-img" alt="NY Times CLI app project screenshot" />
          </div>
          <div className="pl-4 d-flex flex-column">
            <h3 className="pt-2"><strong>New York Times (NYT) Bestsellers</strong></h3>
            <div className="pt-2">
              A command line interface (CLI) application that fetches for various genres of current bestselling books from the NYT API.
              Users can request for further information on each book and receive a link to where they can purchase the book via Amazon.            
            </div>
            <div className="pt-2">
              This application demonstrated excellent understanding of object orientated programming with Ruby classes and functions.
              It fetched, parsed and converted data from the NYT API to JSON readable and usable data.
              Also utilized Ruby conditional looping to ensure user inputs were valid, and used error messaging for invalid inputs.
              Lastly, I imported and used the Colorize Ruby gem to create a more well decorated CLI experience.
            </div>
            <div className="pt-2">
              <strong>Technologies: Ruby, OOP, API, CLI</strong>
            </div>
            <div className="pt-2 project-links">
              <a href="https://www.youtube.com/watch?v=JD4Qi9Ttbic&t=1s" target="blank"><strong>Demo Video</strong></a> | <a href="https://github.com/BeccaN/NYT-Bestsellers" target="blank"><strong>GitHub Link</strong></a>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
