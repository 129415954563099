import React from 'react'

export default function About() {
  return (
    <div className="pt-5" id="about">
      <div className="styled-con d-flex">
        <div className="p-3">
          <img src="me.jpeg" className="pic" alt="Rebecca Congi"/>
          <a href="RebeccaCongi_SE_Resume.pdf" download className="resume-download">
            <button className="download p-2 pt-3 mt-3 d-flex justify-content-center">
              <h5>Resume</h5>
              <svg xmlns="http://www.w3.org/2000/svg" className="ml-3" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.455-.476-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .997.792.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z"/><path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z"/></svg>
            </button>
          </a>
        </div>
        <div className="mt-3">
          <h2>Hello, my name is <strong><u>Rebecca Congi</u></strong> and I am a <strong>full-stack web developer</strong>.</h2>
          <p>
            For several years now I have been working as a textile designer. My responsibilities included working with a <strong>team</strong> of designers and product developers to create exciting and interesting licensed home goods. 
            I worked with major licensors and buyers, such as Minecraft and Target, to establish creative <strong>product designs</strong> that logistically met all parties needs both aesthetically and technically.
            While working as a designer I discovered a new passion for <strong>code</strong> which I started teaching myself on the side.
          </p>
          <p>
            After dabbling in <strong>object orientated programming</strong>, I decided to take the leap and turn this hobby into a career.
            Persuing my dream of making code a career lead me to enrolling in the software engineering online program at <strong>Flatiron Code School</strong>.
            During this time I learned several <strong>technologies</strong>, <strong>code design theories</strong> and became more well acquainted with coding <strong>culture</strong>.
            Completing this program was amazing and challenging. I met interesting people that, like me, were in love with the challenge and intrigue of code. 
          </p>
          <p>
            I love how accepting and <strong>vibrant</strong> the people within this community are. 
            What I find most intriguing about code is that everyone is always learning together.
            There is never a dull moment in the world of <strong>programming</strong> and it is the reason I'm so excited to be a part of it!
          </p>
          <p>
            Now a Flatiron Code School graduate, I wish to <strong>develop</strong> elegant and efficient code, that can meet the needs of users and tell meaningful <strong>stories</strong>.
            My goal is to never stop learning and to keep pushing myself to new heights by confronting new and interesting challenges throughout my <strong>journey</strong>.
          </p>
          <p>
            <u>Technologies:</u>
            <br />
            <strong>
              React • Javascript • Ruby on Rails • Redux • Sinatra • SQL • HTML • CSS • Bootstrap • TailwindCSS • Adobe Illustrator • Adobe Photoshop • MVC • REST • OOP
            </strong>
          </p>
        </div>
      </div>
    </div>
  )
}
